/** @format */

.choose-company-loader-wrapper {
  height: 100vh;
  width: 100%;
  display: block;
  margin-top: -180px;
}
.loader-box {
  width: 100px;
  margin: 40vh auto;
}

.horizontal-seperator-line-container {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 100px;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: -20px;
}

.horizontal-seperator-line-left,
.horizontal-seperator-line-right {
  flex: 1;
  stroke: var(--color-pink);
  stroke-width: 2;
}

.horizontal-seperator-center-text {
  flex: 1;
  text-align: center;
}

.horizontal-seperator-line {
  flex: 1;
  stroke-width: 2;
  stroke: var(--color-black);
}

.horizontal-seperator-line-pink {
  stroke: var(--color-pink);
}

.choose-company-form-button {
  margin-top: 70px !important;
}

.choose-company-form-button-with-pending-approval-alert {
  margin-top: 40px !important;
}

.choose-company-view .pending-approval-alert-btn-small {
  width: 100px;
  height: 35px;
  padding: 1px;
  border: 3px solid var(--color-pink-dark);
}

.choose-company-view .pending-approval-alert {
  margin-top: 0;
  color: var(--color-black);
  background-color: var(--color-pink-light);
  outline: 1px solid var(--color-pink);
  display: flex;
  height: 45px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.choose-company-view .split-layout-left-inner-box-container {
  max-height: 536px;
}

.choose-company-inner-container .split-layout-left-description-container {
  height: auto;
}

@media (max-width: 919px) {
  .choose-company-inner-container {
    padding-top: 25px;
  }

  .choose-company-inner-container .content {
    height: 420px !important;
    padding: 0 20px 0 20px;
  }
}
