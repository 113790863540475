/** @format */

.bankid-redirect-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.bankid-redirect-content {
  margin: auto;
}
