/** @format */

.select-dropdown {
  /* outline: 1px solid var(--color-pink); */
  border: 1px solid var(--color-pink);
  border-radius: 5px;
  /* box-shadow: unset !important;
  outline: unset !important; */
}

.select-dropdown:hover {
  /* outline: 1px solid var(--color-pink); */
  border: 1px solid var(--color-pink);
  border-radius: 5px;
  /* box-shadow: unset !important;
  outline: unset !important; */
}

.select-dropdown:active {
  /* outline: 1px solid var(--color--dark); */
  border: 1px solid var(--color-pink-dark);
  border-radius: 5px;
  /* box-shadow: unset !important; 
  outline: unset !important; */
}

.select-dropdown:focus {
  /* outline: 1px solid var(--color-pink); */
  border: 1px solid var(--color-pink);
  border-radius: 5px;
  /* box-shadow: unset !important;
  outline: unset !important; */
}

.select-dropdown:focus-within {
  /* outline: 1px solid var(--color-pink); */
  border: 1px solid var(--color-pink);
  border-radius: 5px;
  /* box-shadow: unset !important;
  outline: unset !important; */
}

.select-dropdown .__control {
  border: 1px solid var(--color-pink);
}

.select-dropdown .__control:hover {
  border: 1px solid var(--color-pink-dark);
}

.select-dropdown .__control:active {
  outline: 1px solid var(--color-pink) !important;
  border: 1px solid var(--color-pink) !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
}

.select-dropdown .__control:focus,
.select-dropdown .__control:focus-within,
.select-dropdown .__control:visited {
  outline: 1px solid var(--color-pink-dark) !important;
  border: 1px solid var(--color-pink-dark) !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
}

.select-dropdown .__indicator {
  background-color: var(--color-pink);
  color: var(--color-white);
}

.select-dropdown .__indicator:hover {
  background-color: var(--color-pink-dark);
  color: var(--color-white);
}

.select-dropdown .__indicator-separator {
  display: none;
}

.select-dropdown .__value-container {
  text-align: left;
}

.select-dropdown .__indicators,
.select-dropdown .__indicators:hover {
  border: 1px solid var(--color-pink);
  background-color: var(--color-pink);
  color: var(--color-white);
}

.select-dropdown .__menu {
  background-color: var(--color-white);
}

.select-dropdown .__option {
  background-color: var(--color-white);
}

.select-dropdown .__menu-list {
  background-color: var(--color-pink-light);
}

.select-dropdown .__option {
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: 0 0 0 1px var(--color-pink-light);
  text-align: left;
}
.select-dropdown .__option:hover {
  background-color: var(--color-pink-light);
  color: var(--color-black);
}

.select-dropdown .__option:active,
.select-dropdown .__option:visited,
.select-dropdown .__option:focus,
.select-dropdown .__option:focus-visible {
  background-color: var(--color-pink);
  color: var(--color-black);
}
