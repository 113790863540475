/** @format */

.add-employee-view {
  margin: 0 auto;
}

.add-employee-wrapper {
  margin: 20px auto;
  padding-top: 5px;
}

.add-employee-container .loading-indicator-container {
  width: 100px;
}

.add-employee-container .add-employee-heading h1 {
  text-transform: uppercase;
}

.add-employee-container .add-employee-container-rowitem {
  display: flex;
}

.add-employee-container .add-employee-container-left {
  flex: 1;
}

.add-employee-container .add-employee-container-right {
  flex: 2;
}

.add-employee-container .add-employee-container-left h1 {
  text-transform: uppercase;
  font-size: 20px;
}

.add-employee-container .line-item-block {
  display: flex;
  align-items: center;
}

.add-employee-container .add-employee-container-right .line-item-block {
  border-bottom: 1px solid var(--color-grey-semi-light);
}

.add-employee-container
  .add-employee-container-right
  .line-item-block.family-content,
.add-employee-container
  .add-employee-container-right
  .line-item-block.family-content
  .item-row {
  height: unset;
  height: auto;
}

.add-employee-container
  .add-employee-container-right
  .line-item-block:last-child {
  border-bottom: none;
}

.add-employee-container .item-row {
  display: flex;
  flex-direction: row;
}

.add-employee-container .content {
  width: 60%;
  flex: 18;
  height: auto;
  justify-items: flex-end;
  display: flex;
  word-break: break-word;
  margin-right: 10px;
  flex-direction: row;
  align-items: center;
}

.add-employee-container .edit-button-container {
  margin: auto;
  flex: 2;
  justify-content: center;
  align-items: center;
}

.add-employee-container .edit-email-button-container {
  text-align: center;
  margin: 160px -25px 20px;
}

.add-employee-container .icon-edit {
  background-image: var(--icon-edit);
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  flex: 1;
  width: 40px;
  height: 32px;
}

.add-employee-container .icon-container {
  width: 56px;
  height: 56px;
  margin: auto;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.add-employee-container .icon-container .icon-profile-square,
.add-employee-container .icon-container .icon-mobile-phone-square,
.add-employee-container .icon-container .icon-email-square,
.add-employee-container .icon-container .icon-location-square,
.add-employee-container .icon-container .icon-salary-square,
.add-employee-container .icon-container .icon-family-square,
.add-employee-container .icon-container .icon-subscription-square {
  margin: 0 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.add-employee-container .icon-exclamation {
  background-image: var(--icon-exclamation);
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
}

.add-employee-container .text-content {
  flex: 4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-employee-container .name-block .text-content {
  align-items: unset;
}

.add-employee-container .text-content input {
  margin-right: 10px;
}
.add-employee-container .text-content input:last-child {
  margin-right: unset;
}

.add-employee-container .family-content .text-content .form-control {
  flex: 4;
}

.add-employee-container .text-content .unit {
  flex: 1;
  margin-left: 10px;
  word-break: keep-all;
}

.add-employee-container .exclamation-container {
  flex: 1;
  width: 50px !important;
}

.add-employee-container .family-content .icon-exclamation {
  margin-top: -30px;
}

.add-employee-container .family-content .icon-container {
  margin-top: 50px;
}

.add-employee-container .family-content-left .add-employee-container-left {
  margin-top: 25px;
}

.address-container {
  width: 100%;
}

.address-container .address-large {
  display: block;
  width: 100%;
}

.address-container .address-duo {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.address-container .address-duo .address-medium {
  flex: 2;
  margin-right: 5px;
}

.address-container .address-duo .address-small {
  flex: 1;
}

.family-block {
  display: flex;
  flex-direction: column;
}

.family-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.family-container .decrement {
  flex: 1;
  align-self: center;
}

.family-container .input {
  flex: 2;
  align-self: center;
  margin: 0 20px;
}

.family-container .increment {
  flex: 1;
  align-self: center;
}

.icon-plus {
  background-image: var(--icon-plus);
  background-repeat: no-repeat;
  background-size: auto;
  width: 36px;
  height: 36px;
  margin: auto;
}

.icon-minus {
  background-image: var(--icon-minus);
  background-repeat: no-repeat;
  background-size: auto;
  width: 36px;
  height: 36px;
  margin: auto;
}

.child-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.child-container .decrement {
  flex: 1;
  align-self: center;
  width: 36px;
  height: 36px;
}

.child-container .input {
  flex: 2;
  align-self: center;
  margin: 0 20px;
}

.child-container .increment {
  flex: 1;
  align-self: center;
}

.children-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.children-input {
  flex: 1;
  width: 60px;
}

.children-remove {
  flex: 1;
  margin: unset;
}

.children-age-error {
  border: 3px solid var(--color-pink-dark);
}

.add-employee-form-error {
  position: relative;
  width: 100px;
  left: 100px;
  margin-right: -111px;
}

.address-line-block {
  height: 95px !important;
}

.add-employee-button-container {
  margin-top: 20px;
}

.row-heading-container {
  display: flex;
  flex-direction: row;
}

.row-heading {
  flex: 1;
}

.row-heading-exclamation {
  flex: 1;
}

.homepage-view {
  display: flex;
}

.homepage-view .add-employee-prompt {
  flex: 3;
}

.add-multiple-employees-container {
  flex: 2;
}
.homepage-view .add-single-employee-container {
  border-right: 1px solid var(--color-pink);
  padding-right: 40px;
}

.add-employee-prompt {
  display: flex;
  justify-content: space-between;
}

.add-employee-prompt .item-row {
  height: 80px;
}

.add-employee-prompt .row-heading {
  text-transform: uppercase;
  font-size: 20px;
}

.add-employee-prompt .profile-container-right .text-content input {
  width: 310px;
}

.add-employee-prompt
  .profile-container-right
  .text-content
  input[type='checkbox'] {
  width: 35px;
  border: 2px solid var(--color-pink);
}

.add-employee-prompt-button-container {
  display: flex;
  flex-direction: row;
}

.add-employee-prompt .modal-content {
  width: 70%;
}

.add-employee-prompt-button-container {
  justify-content: space-evenly;
}

.add-employee-prompt .message-modal-header {
  font-size: 46px;
  text-align: left;
  margin: auto 42px;
}

.add-employee-prompt form {
  width: 82%;
  margin: auto !important;
}

.toast-message {
  z-index: 1;
  width: 50%;
  height: 100px;
  position: absolute;
  left: 50%;
  margin-left: -25%;
  top: 25%;
  margin-top: -85px;
}

.toast-message .toast {
  max-width: unset;
  background-color: var(--color-grey-light);
  box-shadow: 1px 4px 11px 0 var(--color-grey);
  border: 1px solid var(--color-grey);
}

.toast-message .toast button.close {
  width: 100%;
  text-align-last: end;
}

.toast-message .toast-header {
  background-color: var(--color-grey-light);
}

.tooltip-overlay:hover,
.profile-container-right h1:hover {
  cursor: help;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 14px;
  background-color: var(--color-grey-light);
  border-bottom: 1px solid var(--color-grey);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-body {
  padding: 1rem;
  color: #212529;
}

.add-employee-modal @media (min-width: 920px) {
  .profile {
    margin-left: unset;
    width: unset;
    padding-right: unset;
  }

  .add-employee-wrapper {
    width: 1050px;
    background-color: var(--color-white);
  }

  .add-employee-container .add-employee-heading {
    margin-bottom: 40px;
  }

  .add-employee-container .add-employee-container-right .item-row {
    width: 500px;
  }

  .add-employee-container .line-item-block {
    height: 120px;
  }

  .add-employee-container .add-employee-container-rowitem {
    flex-direction: row;
  }
  .add-employee-container .add-employee-container-right .line-item-block {
    padding-left: 50px;
  }
  .add-employee-container .edit-button-container,
  .add-employee-container .icon-edit {
    width: 42px;
    height: 42px;
  }
  .add-employee-container .icon-exclamation {
    width: 30px;
    height: 30px;
  }

  .box-icons {
    width: 54px;
    height: 54px;
  }
}

@media (max-width: 919px) {
  .profile {
    margin-left: unset;
    width: unset;
    padding-right: unset;
  }

  .add-employee-wrapper {
    background-color: var(--color-white);
    width: 93vw;
    margin: 40px auto;
    width: 100%;
  }

  .add-employee-container .add-employee-heading {
    margin-bottom: 25px;
  }
  .add-employee-container .add-employee-container-right .item-row {
    width: 100%;
  }
  .add-employee-container .line-item-block {
    height: 70px;
  }
  .add-employee-container .item-row {
    height: unset;
  }

  .add-employee-container .add-employee-container-rowitem {
    flex-direction: column;
  }
  .add-employee-container .add-employee-container-right .line-item-block {
    padding-left: unset;
  }
  .add-employee-container .edit-button-container,
  .add-employee-container .icon-edit {
    width: 40px;
    height: 40px;
  }
  .add-employee-container .icon-exclamation {
    width: 26px;
    height: 25px;
  }

  .box-icons {
    width: 45px;
    height: 45px;
  }

  .add-employee-container .text-content,
  .add-employee-container .text-content input {
    font-size: small;
    margin-right: 5px;
  }
  .add-employee-container .text-content:last-child,
  .add-employee-container .text-content input:last-child {
    margin-right: unset;
  }

  .add-employee-container .icon-edit {
    margin-right: 10px;
  }

  .add-employee-container .edit-button-container,
  .add-employee-container .icon-edit {
    width: 30px;
    height: 30px;
  }

  .add-employee-container .content {
    padding-top: unset;
  }

  .add-employee-container .text-content .form-control {
    height: unset;
  }

  .add-employee-container .text-content .form-control.input-first-name,
  .add-employee-container .text-content .form-control.input-last-name {
    margin-bottom: 0;
  }

  .add-employee-container .add-employee-container-left {
    margin-top: unset;
  }

  .add-employee-container .content {
    margin-right: unset;
  }

  .add-employee-container .text-content .unit {
    margin-left: unset;
  }

  .add-employee-button-container {
    margin: 20px -25px;
  }

  .add-employee-container .icon-exclamation {
    width: 20px;
    height: 20px;
    margin: 0 -10px;
  }

  .family-content {
    margin-top: 15px;
  }

  .list-item-left {
    display: none;
  }
}

@media (max-width: 767px) {
  .add-employee-wrapper {
    background-color: var(--color-white);
    width: 90vw;
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  .add-employee-container .address-container .address-duo .address-small {
    margin-right: unset;
  }
  .add-employee-container .add-employee-heading h1 {
    font-size: x-large;
  }
  .add-employee-container .add-employee-container-left h1 {
    font-size: medium;
    height: 25px;
  }

  .add-employee-container .add-employee-heading {
    margin-bottom: unset;
  }

  .add-employee-container .add-employee-container-left {
    margin-top: 20px;
  }

  .add-employee-container .line-item-block {
    height: 60px;
  }

  .add-employee-container .item-row {
    height: 40px;
  }

  .add-employee-container .content {
    padding-top: 5px;
  }

  .box-icons {
    width: 35px;
    height: 35px;
  }

  .list-container .list-item-middle {
    padding-left: 10px;
  }

  .add-employee-container-right .item-col.icon-container {
    display: none;
  }

  .add-employee-container .add-employee-container-right .line-item-block {
    border-bottom: unset;
  }

  .family-block {
    margin: 10px auto;
  }

  .go-previous-button-dashboard {
    display: none;
  }

  .add-employee-container .text-content,
  .add-employee-container .text-content input {
    margin-right: 5px;
  }
  .add-employee-container .text-content:last-child,
  .add-employee-container .text-content input:last-child {
    margin-right: unset;
  }
}

@media (min-width: 410px) {
  .list-item-left {
    display: block;
  }
}
