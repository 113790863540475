/** @format */

.radio-label {
  text-align: left;
  margin-left: -8px;
  margin: 25px 0 0 -25px;
}

input[type='radio'],
input[type='radio']:focus {
  box-shadow: none;
  outline: 0px;
  width: 0px;
  height: 0px;
  border: 0px;
  visibility: hidden;
}

input[type='radio']:after {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: relative;
  top: 4px;
  left: -8.4px;
  background-color: var(--color-white);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--color-pink);
}

input[type='radio']:checked:after {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: relative;
  top: 4px;
  left: -8.4px;
  background-color: var(--color-white);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 10px solid var(--color-pink);
}
