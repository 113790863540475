/** @format */

.modal-dialog {
  max-width: unset !important;
  max-width: 766px;
  width: 100vw;
  height: 48%;
  max-height: auto;
  margin: 15% auto;
}

.validate-email-modal {
  z-index: 1048;
}

.validate-email-modal .modal-content {
  margin: 0 auto;
  height: 500px;
  margin-top: 20vh;
}
.validate-email-modal .modal-container {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 450px;
}
.validate-email-modal .validate-email-modal-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-align: -webkit-center;
  flex: 2;
}

.validate-email-modal .validate-email-modal-description {
  margin: 25px 0 -25px;
}
.validate-email-modal-button {
  margin-top: 35px;
}

.validate-email-modal-form-control {
  border: none;
  height: 50px;
  width: 350px;
  letter-spacing: 49px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  margin: 0 auto;
  background: var(--image-background-validation-code) no-repeat;
}

.validate-email-modal-form-control:hover,
.validate-email-modal-form-control:focus,
.validate-email-modal-form-control:active {
  border: none;
  box-shadow: none;
}

.validate-email-modal .validate-email-modal-body {
  flex: 3;
  margin: 0 auto;
}

.validate-email-modal .validating-email-circle {
  margin: 0;
  display: block;
}

.validate-email-modal .loading-indicator-container {
  width: unset !important;
}

.validate-email-modal .validate-email-modal-buttons {
  display: flex;
}

.validate-email-modal .validate-email-modal-icon {
  flex: 1;
}
.validate-email-modal .validate-email-modal-heading {
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  flex: 1;
}

.validate-email-modal .btn {
  flex: 1;
}

.validate-email-modal .loading-indicator-container {
  width: 100px;
  margin-top: 16%;
}

.validate-email-modal .icon-exclamation-black {
  background-image: var(--icon-exclamation-black);
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 5px;
  width: 30px;
  height: 30px;
}

@media (min-width: 900px) {
  .validate-email-modal .modal-content {
    width: 650px;
  }
  .validate-email-modal .modal-container {
    width: 500px;
  }
  .validate-email-modal .validate-email-modal-buttons {
    flex-direction: row;
  }
  .validate-email-modal .btn {
    margin: auto 6px;
  }
}

@media (max-width: 899px) {
  .validate-email-modal .modal-content {
    width: 75vw;
  }
  .validate-email-modal .modal-container {
    width: 65vw;
  }
  .validate-email-modal .validate-email-modal-buttons {
    flex-direction: column;
    align-self: center;
  }
  .validate-email-modal .btn {
    margin: 6px auto;
  }
}

@media (max-width: 549px) {
  .validate-email-modal .modal-content {
    width: 90vw;
  }

  .validate-email-modal .validate-email-modal-buttons {
    flex-direction: column;
    align-self: center;
  }
  .validate-email-modal .btn {
    margin: 6px auto;
    width: 70vw;
  }
}

@media (max-width: 919px) {
  .validate-email-modal {
    top: -50px;
    font-size: small;
  }

  .validate-email-modal .modal-content {
    width: 98vw;
    border-radius: 0px;
  }

  .validate-email-modal .modal-container {
    width: 80vw;
    height: 400px;
  }
  .validate-email-modal .validate-email-modal-buttons {
    flex-direction: column;
    align-self: center;
  }
  .validate-email-modal .btn {
    margin: 6px auto;
  }
}

.validate-update-email-success {
  margin: 80px auto;
}
