/** @format */

.centered-container {
  margin: 0 -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-container-default-background-image {
  background-image: var(--image-background-login-redirect);
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.centered-content {
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.centered-content-top {
  flex: 1;
  align-self: center;
  text-align: center;
}

.centered-content-bottom {
  flex: 1;
  margin: auto;
}

.centered-content-theme-black {
  background-color: var(--color-black-medium);
  border: 1px solid var(--color-grey-medium);
  color: var(--color-white);
}

.centered-content-theme-white {
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-medium);
  color: var(--color-black);
}

.centered-content-theme-transparent {
  background-color: unset;
  border: none;
  color: var(--color-white);
}

@media (min-width: 768px) {
  .centered-content {
    height: 64vh;
    flex: 0.5;
  }
}
@media (max-width: 767px) {
  .centered-content {
    height: 64vh;
    flex: 1;
  }
}

@media screen and (max-width: 919px) and (orientation: landscape) {
  .centered-content {
    height: 64vw;
    flex: 1;
  }
}
