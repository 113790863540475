/** @format */

.iframe-ssn {
  width: 302px;
  background-color: transparent;
  border: 0px none transparent;
  padding: 0px;
  overflow: hidden;
  margin: auto;
  height: 180px;
}

.login-view .split-layout-left {
  margin-top: 10px;
}

@media (max-width: 919px) {
  .iframe-ssn {
    width: 100%;
  }
}

.login-description-container {
  margin: 0 20px;
  overflow: hidden;
  height: 74px;
}

.login-ssn-label-container {
  width: 302px;
  margin: 0 auto -20px;
  font-family: var(--font-din);
  font-weight: bold;
  z-index: 1;
  letter-spacing: 0.6px;
  text-align: left;
}

.ssn-input-container {
  margin-top: -120px;
}

.another-device-container {
  margin-top: -50px;
}

.another-device-container .loader-wrapper {
  bottom: -30px;
  animation: fadeOut 3s;
  opacity: 0;
}

.same-device-container {
  margin-top: -250px;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
