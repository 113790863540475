/** @format */

.logout-container {
  color: var(--color-white);
}

.logout-container .loading-indicator-container {
  margin: auto;
  width: 100px;
  /***/
}
