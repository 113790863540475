/** @format */

.switch {
  position: relative;
  display: inline-block;
  width: 77px;
  height: 40px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--color-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.slider-border-pink {
  border: 2px solid var(--color-pink);
}

.slider:before {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  left: -2px;
  bottom: -2px;
  background-color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-pink);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-pink);
}

input:checked + .slider:before {
  -webkit-transform: translateX(37px);
  -ms-transform: translateX(37px);
  transform: translateX(37px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  border: 2px solid var(--color-grey);
}

.slider-border-pink:before {
  border: 2px solid var(--color-pink) !important;
}

.agree-terms-toggle-container {
  display: flex;
  justify-content: center;
}

.agree-terms-toggle-text-container {
  font-size: 12px;
  width: 210px;
  margin-left: 16px;
  text-align: justify;
}

@media screen and (max-width: 919px) {
  /** @format */
  .switch {
    width: 67px;
    height: 35px;
  }

  .slider:before {
    height: 35px;
    width: 35px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }
}
