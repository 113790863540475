/** @format */

.summary-box {
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 50%;
}
.summary-box-summary {
  flex: 1;
}
.summary-box-locality-block {
  display: flex;
  flex: 1;
  height: 40px;
}
.summary-box-locality-block-postcode {
  flex: 1;
}
.summary-box-locality-block-city {
  flex: 1;
}

.summary-box-content {
  flex: 1;
}

.summary-box-heading {
  flex: 1;
  text-align: left;
  text-transform: uppercase;
}

.summary-box-content {
  text-align: left;
  width: 200px;
}

.summary-box-subheading {
  text-align: left;
}

.summary-view .block-container {
  flex-direction: row;
}

.summary-view .block-container .left-block {
  padding-right: unset;
}

.summary-box-address {
  height: 40px;
}

.horizontal-line-long {
  margin: 15px 0;
  width: 100%;
  height: 20px;
}

.summary-box-right {
  margin: 12px 0;
}

.icon-tick-pink {
  background-image: var(--icon-tick-pink-24);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}

.signee-email-container {
  display: flex;
  width: 250px;
  text-align: left;
}
.icon-tick-pink,
.icon-container {
  width: 50px;
  flex: 1;
}
.signee-email {
  min-width: 200px;
  flex: 5;
}
