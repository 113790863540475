/** @format */

@charset "UTF-8";

@font-face {
  font-family: 'DINCondensed';
  src: url('/fonts/DINCondensed.woff2') format('woff2'),
    url('/fonts/DINCondensed.woff') format('woff'),
    url('/fonts/DINCondensed.otf') format('otf');
}

@font-face {
  font-family: 'Playfair Display';
  src: url('/fonts/PlayfairDisplay.woff2') format('woff2'),
    url('/fonts/PlayfairDisplay.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto.woff2') format('woff2'),
    url('/fonts/Roboto.ttf') format('ttf');
}

:root {
  --color-pink: #fa7278;
  --color-pink-dark: #f36269;
  --color-pink-light: #fac7c6;
  --color-white: #ffffff;
  --color-grey: #bfb8af;
  --color-grey-light: #ece8e4;
  --color-grey-semi-light: #bfb8af;
  --color-grey-medium: #95989a;
  --color-grey-semi-dark: #575757;
  --color-grey-dark: #2f2f2f;
  --color-black-medium: #141313;
  --color-black: #0c0c0c;
  --color-blue-light: #459bbe;
  --color-blue-medium: #0069d9;
  --color-blue-semi-dark: #005cbf;
  --color-blue-dark: #0062cc;

  --breakpoint-large-start: 1200px;
  --breakpoint-medium-start: 768px;
  --breakpoint-medium-end: 1149px;
  --breakpoint-small-start: 400px;
  --breakpoint-small-end: 767px;

  --font-roboto-primary: 'Roboto';
  --font-din: 'DINCondensed';
  --font-playfair: 'Playfair Display';
  --font-source-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

  /** Split screen background images **/
  --image-background-registration-user-layout: url('../../images/common/image-background-registration-user-layout-left-large.jpg');
  --image-background-registration-welcome: url('../../images/common/image-background-registration-welcome-left-large.jpg');

  /** common images **/
  --image-background-login-redirect: url('../../images/common/image-background-registration-login-redirect-large.jpg');
  --image-background-registration-complete: url('../../images/common/image-background-registration-complete.jpg');
  --image-background-page-not-found: url('../../images/common/image-background-page-not-found.png');
  --image-triple-lines: url('../../images/common/image-triple-lines.png');
  --image-success: url('../../images/common/image-success.svg');
  --image-user-location: url('../../images/common/image-user-location.png');
  --image-background-validation-code: url('../../images/common/image-background-validate-code.png');
  --image-background-validation-code-small: url('../../images/common/image-background-validate-code-small.png');
  --image-tick: url('../../images/common/image-tick.png');
  --image-hamburger: url('../../images/common/image-hamburger.png');
  --image-sustainability: url('../../images/common/image-sustainability.png');

  /* logos */
  --logo-bankid: url('../../images/common/logo-bankid.png');
  --logo-the-third-act: url('../../images/common/logo-the-third-act.png');
  --logo-the-third-act-white: url('../../images/common/logo-the-third-act-white.svg');
  --logo-fullmaktskollen: url('../../images/common/logo-fullmaktskollen.png');
  --logo-playstore-black: url('../../images/common/logo-playstore-black.svg');
  --logo-appstore-black: url('../../images/common/logo-appstore-black.svg');
  --logo-appstore-white: url('../../images/common/logo-appstore-white.svg');
  --logo-insuresec-white: url('../../images/common/logo-insuresec-white.svg');
  --logo-swedsec-white: url('../../images/common/logo-swedsec-white.svg');
  --logo-sfm-white: url('../../images/common/logo-sfm-white.svg');

  /* icons */
  --icon-profile: url('../../images/common/icon-profile.svg');
  --icon-mobile-phone: url('../../images/common/icon-mobile-phone.svg');
  --icon-email: url('../../images/common/icon-email.svg');
  --icon-power-of-attorney: url('../../images/common/icon-power-of-attorney.svg');
  --icon-location: url('../../images/common/icon-location.svg');
  --icon-invoice: url('../../images/common/icon-invoice.svg');
  --icon-payment-error: url('../../images/common/icon-payment-error.svg');
  --icon-edit: url('../../images/common/icon-edit.svg');
  --icon-salary: url('../../images/common/icon-salary.svg');
  --icon-family: url('../../images/common/icon-family.svg');
  --icon-go-previous: url('../../images/common/icon-go-previous.png');
  --icon-previous-pink: url('../../images/common/icon-previous-pink.svg');
  --icon-exclamation: url('../../images/common/icon-exclamation.svg');
  --icon-exclamation-black: url('../../images/common/icon-exclamation-black.svg');
  --icon-hamburger: url('../../images/common/icon-hamburger.svg');
  --icon-subscription: url('../../images/common/icon-subscription.svg');
  --icon-go-forward: url('../../images/common/icon-go-forward.svg');
  --icon-plus: url('../../images/common/icon-plus.svg');
  --icon-plus-hover: url('../../images/common/icon-plus-hover.svg');
  --icon-minus: url('../../images/common/icon-minus.svg');
  --icon-action-exclamation: url('../../images/common/icon-action-exclamation.svg');
  --icon-arrow-left: url('../../images/common/icon-arrow-left.svg');
  --icon-logout: url('../../images/common/icon-logout.svg');
  --icon-tick-pink-24: url('../../images/common/icon-tick-pink-24.png');
  --icon-tick-pink-48: url('../../images/common/icon-tick-pink-48.png');
  --icon-dashboard: url('../../images/common/icon-dashboard.svg');
  --icon-billing: url('../../images/common/icon-billing.svg');
  --icon-employees: url('../../images/common/icon-employees.svg');

  /* icons with square background */

  --icon-profile-square-background: url('../../images/common/icon-profile-square-background.svg');
  --icon-subscription-square-background: url('../../images/common/icon-subscription-square-background.svg');
  --icon-salary-square-background: url('../../images/common/icon-salary-square-background.svg');
  --icon-family-square-background: url('../../images/common/icon-family-square-background.svg');
  --icon-email-square-background: url('../../images/common/icon-email-square-background.svg');
  --icon-location-square-background: url('../../images/common/icon-location-square-background.svg');
  --icon-mobile-phone-square-background: url('../../images/common/icon-mobile-phone-square-background.svg');

  /* table icons */
  --icon-table-default-arrow: url('../../images/table/icon-table-default-arrow.png');
  --icon-table-up-arrow: url('../../images/table/icon-table-up-arrow.png');
  --icon-table-down-arrow: url('../../images/table/icon-table-down-arrow.png');
}

body {
  font-family: var(--font-roboto-primary);
  font-size: 16px;
  background-color: var(--color-grey-light);
  color: var(--color-black);
}

button {
  color: var(--color-white);
  background-color: var(--color-pink);
  border-color: var(--color-pink);
  height: 50px;
  width: 302px;
  font-weight: 100;
  letter-spacing: 1.3 px;
  font-family: var(--font-din);
  font-size: 18px;
  text-transform: uppercase;
}

code {
  font-family: var(--font-source-code);
}

h1 {
  font-family: var(--font-din);
  font-size: 26px;
}

h2 {
  font-family: var(--font-playfair);
  font-size: 16px;
}
h5 {
  font-family: var(--font-playfair);
}

.column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.container-fluid {
  padding: unset;
}

.main-container {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 500px) {
  .main-container .go-previous-button {
    margin-left: unset;
  }
}

@media (max-width: 499px) {
  .main-container {
    width: 100vw;
    margin: 16px 0 16px -15px;
  }

  .main-container .go-previous-button {
    margin-left: 20px;
  }
}

@media (max-width: 919px) {
  .main-container {
    width: 100vw;
    margin: 0 0 0 -16px;
  }

  body {
    background-color: var(--color-white);
    font-size: small;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: large;
  }
}
