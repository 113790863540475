/** @format */

.action-required-message-container {
  display: flex;
  background-color: var(--color-pink-light);
  border-radius: 10px;
  margin: 10px 50px 25px;
  border: 1px solid var(--color-grey-semi-light);
}
.action-required-message-container-left {
  flex: 1;
}

.action-required-message-container-right {
  flex: 8;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}

.action-required-message-content h2 {
  font-size: 22px;
  font-family: var(--font-din);
  text-align: center;
  padding: 25px 25px 2px;
}

.action-required-message-content-dismiss {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: var(--font-din);
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.25px;
}

.icon-action-exclamation {
  background-image: var(--icon-action-exclamation);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 64px;
  height: 64px;
  margin: 50px 40px;
}

@media (min-width: 1050px) {
  .action-required-message-content {
    padding: 10px;
  }
}

@media (max-width: 1049px) {
  .action-required-message-container {
    margin: 5px 0px 25px;
  }

  .action-required-message-content {
    padding: 2px;
  }

  .action-required-message-content .heading {
    padding-top: 5px;
  }
}

@media (max-width: 749px) {
  .action-required-message-container {
    margin: -25px -25px 20px;
    border-radius: 1px;
  }

  .action-required-message-content .button {
    margin-top: 10px;
  }

  .action-required-message-container-left {
    display: none;
  }

  .action-required-message-container h2 {
    font-size: large;
  }
  .action-required-message-container a,
  .action-required-message-container .btn {
    font-size: small;
  }

  .action-required-message-content .button {
    margin-top: 10px;
  }
}

@media (max-width: 919px) {
  .action-required-message-container {
    margin: -5px -25px 20px;
  }
  .action-required-message-container h2 {
    font-size: medium;
  }
  .action-required-message-container a,
  .action-required-message-container .btn {
    font-size: revert;
  }
}
