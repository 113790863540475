/** @format */

.navbar {
  padding: unset;
}

.menu-switch {
  text-align: left;
  height: 117px;
  margin-top: -41px;
  border: 1px solid var(--color-black);
  margin-right: 0px;
}

.menu-switch:hover {
  cursor: pointer;
}

.menu-wrapper {
  width: 100%;
  max-width: 1200px;
  display: flex;
  color: var(--color-white);
  font-family: var(--font-din);
  font-size: 16px;
  margin: auto;
}

.menu-left-container {
  flex: 3;
  display: flex;
  flex-direction: row;
  max-width: 60%;
  margin-left: 10%;
  margin-right: 2%;
}

.menu-right-container {
  flex: 1;
  max-width: 184px;
}

.menu-right-container .menu-button {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.menu-right-container .menu-button .icon-logout {
  flex: 1;
  align-self: self-start;
}

.menu-right-container .menu-button .button-logout {
  flex: 3;
  align-self: center;
}

.menu-left-container .menu-left {
  flex: 1;
  width: 20%;
}

.menu-left-container .menu-center {
  flex: 1;
  width: 20%;
}

.menu-left-container .menu-right {
  flex: 1;
  width: 20%;
}

.menu-wrapper .menu-heading-container {
  text-align: center;
  border-bottom: 2px solid var(--color-white);
  width: 100%;
  height: 70px;
  padding: 20px 0px;
  margin-bottom: 20px;
}

.icon-logout {
  background-image: var(--icon-logout);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 29px;
  margin-right: 10px;
}

.hamburger-dropdown .dropdown-menu {
  width: 300px;
  border-radius: 0px;
  min-height: 340px;
  text-align: end;
  right: -18px;
  left: auto;
  width: 100vw;
  border: unset;
  margin-top: -99px;
  background-color: var(--color-black);
}

.hamburger-dropdown .dropdown-item {
  color: var(--color-white);
  text-decoration: none;
  text-align: left;
  border-radius: 0;
  width: 100%;
  background-color: var(--color-black);
  border-bottom: 6px solid var(--color-black);
}

.hamburger-dropdown .dropdown-item:hover {
  background-color: var(--color-grey-dark);
  border-bottom-color: var(--color-pink);
}

.hamburger-dropdown .dropdown-item:active {
  color: var(--color-black);
  background-color: var(--color-grey);
  border-top: 1px solid var(--color-pink-light);
  border-bottom-color: var(--color-pink-light);
}

.hamburger-dropdown .dropdown-item:focus-within {
  color: var(--color-pink);
  background-color: var(--color-pink-light);
  border-top-color: var(--color-pink-dark);
  border-bottom-color: var(--color-pink-dark);
}

.hamburger-dropdown .dropdown-item:focus-visible {
  border: none;
  outline: none;
}

@media screen and (min-width: 1200px) {
  .menu-switch {
    width: calc((100vw - 1120px) / 2 + 25px);
  }
}

@media screen and (max-width: 1199px) {
  .menu-switch {
    width: 100px;
  }
  .menu-wrapper {
    width: 100%;
    font-size: 14px;
  }
  .menu-wrapper h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 919px) {
  .menu-switch {
    height: 70px;
    margin-right: 1px;
    margin-top: 1px;
    z-index: 1200;
    margin-bottom: -45px;
  }
  .dropdown-toggle {
    display: none;
  }

  .hamburger-dropdown .dropdown-menu {
    width: 100vw;
    margin: 0px;
    position: absolute;
    left: calc(-100vw + 54px);
    top: 46px;
    border-radius: 0px;
    text-align: inherit;
    background-color: var(--color-black);
  }

  .hamburger-dropdown .dropdown-menu.registration-flow {
    min-height: unset;
  }

  .hamburger-dropdown .dropdown-menu.registration-flow .menu-wrapper {
    width: 100%;
  }

  .dropdown-menu.registration-flow .menu-right-container {
    max-width: unset;
    width: 100%;
    margin-bottom: unset;
  }

  .dropdown-menu.registration-flow .menu-wrapper .menu-heading-container {
    height: unset;
  }
}

@media screen and (max-width: 767px) {
  .menu-left-container {
    flex-direction: column;
  }

  .menu-wrapper {
    width: 70%;
  }

  .menu-left-container .menu-left,
  .menu-left-container .menu-center,
  .menu-left-container .menu-right {
    width: 92%;
  }

  .menu-wrapper .menu-heading-container {
    text-align: left;
  }
  .menu-left-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 479px) {
  .hamburger-dropdown .dropdown-menu .menu-wrapper {
    flex-direction: column;
  }

  .menu-wrapper .menu-heading-container {
    width: 100%;
  }

  .hamburger-dropdown .dropdown-item {
    padding: unset;
    width: 100%;
  }

  .dropdown-menu.registration-flow
    .menu-right-container
    .menu-button.dropdown-item {
    padding-left: 180px;
  }

  .menu-left-container .menu-left,
  .menu-left-container .menu-center,
  .menu-left-container .menu-right {
    width: 100%;
  }

  .menu-left-container {
    margin: auto;
    width: 100%;

    max-width: unset;
    margin: auto;
  }

  .menu-right-container {
    width: 66%;
    max-width: unset;
    margin: auto;
  }
}
