/** @format */

.validating-email-circle {
  background-color: white;
  border-radius: 50%;
  margin: auto;
  display: inline-block;
}
.validating-email-container {
  height: 490px;
  text-align: left;
}
.validating-email-no-right-side-background {
  background-color: unset;
}

.image-success {
  background-image: var(--image-success);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 90px;
  margin: 130px 120px;
}
