/** @format */

.footer {
  margin: 0 0 0 -15px;
  width: calc(100% + 30px);
  min-height: 240px;
  height: auto;
  background-color: var(--color-grey-semi-dark);
  flex: 1 1;
}

.footer-inner {
  margin: 0 auto;
  max-width: 1200px;
  width: auto;
  min-height: 240px;
  height: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.footer-inner .footer-top-container {
  width: 100%;
  padding: 30px 0;
  flex: 5;
  display: flex;
  flex-direction: row;
  align-self: start;
}

.footer-main-logo-content .logo-the-third-act-white {
  width: 160px;
  height: 94px;
  margin: 5px 0;
}

.footer-bottom-outer-wrapper {
  flex: 1;
  align-self: flex-end;
  height: auto;
  color: white;
  background-color: black;
  width: 100%;
}

.footer-bottom-container {
  flex: 1;
  align-self: flex-end;
  height: auto;
  color: white;
  padding: 20px;
  max-width: 1200px;
  width: auto;
  text-align: left;
  margin: auto;
}

.footer-main-logo-content {
  flex: 1;
}

.footer-main-text-content {
  flex: 3;
  display: flex;
  flex-direction: row;
  padding: 0 15px;
}

.footer-center-container {
  margin: auto;
}

.footer-mobile-app-icons-container {
  margin: 15px;
  display: flex;
  flex-direction: row;
}

.footer-center-container
  .footer-mobile-app-icons-container
  .logo-appstore-black,
.footer-center-container
  .footer-mobile-app-icons-container
  .logo-playstore-black {
  max-width: 166px;
  max-height: 58px;
}

.footer-main-membership-content {
  flex: 2;
  display: flex;
}

.footer-inner .footer-link-container {
  min-width: 130px;
}

.footer-inner a,
.footer-inner a li,
.footer-inner .footer-link-container,
.footer-main-text-content a {
  list-style-type: none;
  color: var(--color-white);
  text-decoration: none;
  font-weight: 100;
}

.footer-main-text-content ul {
  padding-inline-start: unset;
}

.footer-inner h1 {
  font-size: 24px;
}

.footer-main-text-content .footer-main-text-content-left {
  flex: 1;
}

.footer-main-text-content .footer-main-text-content-middle {
  flex: 1;
  padding: 0 15px;
}

.footer-main-text-content .footer-main-text-content-right {
  flex: 1;
}

.footer-membership-content {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  max-width: 410px;
}

.footer-membership-content .footer-membership-text {
  flex: 1;
}

.footer-membership-content .footer-membership-text ul {
  margin-left: -40px;
}

.footer-membership-content .footer-membership-logos,
.footer-center-container .footer-membership-logos {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: space-evenly;
  width: 100%;
}

.logo-insuresec-white {
  background-image: var(--logo-insuresec-white);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 50px;
  margin-top: -3px;
}

.logo-swedsec-white {
  background-image: var(--logo-swedsec-white);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 53px;
  height: 39px;
}

.logo-sfm-white {
  background-image: var(--logo-sfm-white);
  background-repeat: no-repeat;
  background-position: center;
  width: 254px;
  height: 40px;
}

.footer-bottom-container .footer-copyright-top-container ul,
.footer-bottom-container .footer-copyright-bottom-container ul {
  list-style-type: none;
  padding-inline-start: 0px;
}

@media screen and (min-width: 1200px) {
  .footer-center-container .membership-logo-container {
    display: none;
  }
  .footer-membership-content .membership-logo-container {
    display: block;
  }
}

@media screen and (max-width: 1199px) {
  .footer-inner {
    padding: 0 25px;
  }

  .footer-center-container .membership-logo-container {
    display: flex;
  }
  .footer-membership-content .membership-logo-container {
    display: none;
  }

  .footer-inner .footer-top-container {
    font-size: 12px;
  }

  .footer-inner h1 {
    font-size: 20px;
  }

  .footer-bottom-container {
    font-size: 10px;
  }

  .footer-main-logo-content .logo-the-third-act-white {
    width: 110px;
    height: 64px;
  }

  .footer-center-container
    .footer-mobile-app-icons-container
    .logo-appstore-black,
  .footer-center-container
    .footer-mobile-app-icons-container
    .logo-playstore-black {
    max-width: 141px;
    max-height: 50px;
  }

  .logo-insuresec-white {
    width: 63px;
    height: 45px;
    margin-top: -3px;
  }

  .logo-swedsec-white {
    width: 48px;
    height: 35px;
  }

  .logo-sfm-white {
    width: 229px;
    height: 36px;
  }
}

@media screen and (max-width: 919px) {
  .footer-inner .footer-top-container .footer-main-logo-content {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer-inner .footer-top-container {
    flex-direction: column;
  }
  .footer-membership-content {
    margin: auto;
  }
  .footer-membership-content .footer-membership-text {
    text-align: center;
  }
}

@media screen and (max-width: 479px) {
  .footer-center-container .membership-logo-container {
    display: none;
  }

  .footer-membership-content .membership-logo-container {
    display: flex;
  }

  .footer-inner .footer-top-container {
    margin: auto;
  }

  .footer-membership-content {
    text-align: center;
  }

  .footer-inner .footer-top-container .footer-main-text-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-membership-content {
    padding: unset;
  }

  .footer-membership-content .footer-membership-logos {
    flex-direction: column;
  }

  .footer-membership-content .footer-membership-logos div {
    margin: 10px auto;
  }

  .footer-bottom-container ul {
    padding-inline-start: unset;
    padding: 15px;
  }

  .footer-mobile-app-icons-container {
    margin: 15px auto;
  }

  .footer-membership-content .footer-membership-logos {
    flex-direction: column;
  }

  .footer-center-container
    .footer-mobile-app-icons-container
    .logo-appstore-black,
  .footer-center-container
    .footer-mobile-app-icons-container
    .logo-playstore-black {
    max-width: 133px;
    max-height: 46px;
  }
}
