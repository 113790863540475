/** @format */
.registration-complete-background-image {
  background-image: var(--image-background-registration-complete);
  width: auto;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.registration-complete-top-container.centered-content-top {
  padding: 38px 30px 0;
  width: 462px;
}

.registration-complete-top-container h1 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 25px;
}

.registration-complete-container.centered-container {
  color: var(--color-white);
}

.registration-complete-container.centered-container.dashboard-flow {
  color: var(--color-black);
}

.registration-complete-top-container .image-success {
  margin: 25px auto;
  width: 60px;
  height: 46px;
}

.centered-content-bottom h2 {
  text-transform: uppercase;
  font-size: 22px;
  font-family: var(--font-din);
}

@media (min-width: 900px) and (max-width: 1023px) {
  .registration-complete-top-container {
    padding: 38px 30px 0;
  }
}

@media (min-width: 600px max-width: 899px) {
  .registration-complete-top-container h1 {
    font-size: 34px;
    text-align: center;
    margin-bottom: 25px;
  }

  .registration-complete-top-contentlist {
    font-size: small;
    text-align: center;
  }
}

@media (max-width: 599px) {
  .registration-complete-top-container.centered-content-top {
    width: unset;
  }
  .registration-complete-top-container h1 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 25px;
  }

  .registration-complete-top-contentlist {
    text-align: center;
    width: 80%;
  }
}
@media (min-width: 920px) {
}

@media (max-width: 919px) {
  .registration-complete-top-container h1 {
    font-size: x-large;
  }
  .registration-complete-container .centered-content {
    height: unset;
  }

  .registration-complete-container .logo-the-third-act-white {
    width: 102px;
    height: 60px;
  }

  .registration-complete-top-contentlist {
    font-size: smaller;
    margin: auto;
  }
}
