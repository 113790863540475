/** @format */

.react-datepicker-wrapper {
  display: table-cell !important;
  width: 150px;
}

.react-datepicker {
  border: 1px solid var(--color-pink) !important;
  font-family: var(--font-roboto-primary) !important;
}

.react-datepicker-popper[data-placement^='top']
  .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: var(--color-pink) !important;
}

.react-datepicker__header {
  background-color: var(--color-grey-light) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border: 1px solid var(--color-pink-dark) !important;
  border-radius: 0.3rem !important;
  background-color: var(--color-pink) !important;
}

.react-datepicker__day:focus,
.react-datepicker__month-text:focus,
.react-datepicker__quarter-text:focus,
.react-datepicker__year-text:focus {
  outline: var(--color-pink-dark) auto 1px !important;
}

.react-datepicker__navigation--next {
  border-left-color: var(--color-grey-medium) !important;
}

.react-datepicker__navigation--next:hover {
  border-left-color: var(--color-pink-dark) !important;
}

.react-datepicker__navigation--next:focus {
  outline: unset !important;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--color-grey-medium) !important;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: var(--color-pink-dark) !important;
}

.react-datepicker__navigation--previous:focus {
  outline: unset !important;
}

@media (max-width: 919px) {
  .react-datepicker-popper[data-placement^='top'] {
    left: -82px !important;
  }

  .react-datepicker-wrapper {
    width: 110px;
  }
  .react-datepicker-wrapper .form-control {
    font-size: small;
  }
}
