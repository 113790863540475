/** @format */

.company-info-form-control {
  border: 1px solid var(--color-pink);
  height: 50px;
  width: 302px;
}

.company-info-form-control:hover {
  border: 2px solid var(--color-pink);
}

.company-info-container .subheading-text {
  text-transform: uppercase;
}

.row-reset-height {
  height: unset !important;
}

.company-info-container .split-layout-left-heading-container {
  height: unset;
}

.add-top-margin-14 {
  margin-top: 14px;
}

@media (min-width: 920px) {
  /* .company-info-container .content {
    height: 460px;
  } */
}

@media (max-width: 919px) {
  .company-info-view .form-group .content .address2-container {
    flex-wrap: nowrap;
  }

  .company-info-container .split-layout-left-heading-container,
  .company-info-container .split-layout-left-subheading-container,
  .company-info-container .form-group {
    padding: 25px 25px 0;
  }

  .company-info-container .form-group {
    display: flex;
    flex-direction: column;
  }

  .company-info-container .form-group .content {
    flex: 1;
  }

  .company-info-container .form-group .split-layout-left-form-button-container {
    flex: 1;
    margin: 25px -25px 0;
  }

  .company-info-view .split-layout-left-box.postlogin-template {
    height: 100%;
  }
}
