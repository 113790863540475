/** @format */

.checkbox {
  width: 100%;
  margin: 15px auto;
  position: relative;
  display: flex;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 34px;
  height: 34px;
  padding: 8px;
  background-clip: content-box;
  border: 1.5px solid var(--color-pink-dark);
  border-radius: 1px;
  background-color: var(--color-white);
  margin-left: 15px;
  margin-right: 15px;
}
input[type='checkbox']:checked {
  background-color: var(--color-pink);
}

input[type='checkbox']:focus {
  outline: none !important;
}
