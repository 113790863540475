/** @format */

.steps-wizard-container {
  height: 86px;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 450px;
}

.steps-wizard-container svg {
  flex: 2;
  width: 450px;
  height: 46px;
  margin-left: -25px;
}

.steps-wizard-circle-0-active,
.steps-wizard-circle-1-active,
.steps-wizard-circle-2-active,
.steps-wizard-circle-3-active,
.steps-wizard-circle-4-active {
  stroke: var(--color-pink);
  stroke-width: 2;
  fill-opacity: 0;
}

.steps-wizard-circle-0-inactive,
.steps-wizard-circle-1-inactive,
.steps-wizard-circle-2-inactive,
.steps-wizard-circle-3-inactive,
.steps-wizard-circle-4-inactive {
  display: none;
}

.steps-wizard-dot-0-active,
.steps-wizard-dot-1-active,
.steps-wizard-dot-2-active,
.steps-wizard-dot-3-active,
.steps-wizard-dot-4-active {
  fill: var(--color-grey);
  stroke-width: 4;
  stroke: var(--color-pink);
}

.steps-wizard-dot-0-inactive,
.steps-wizard-dot-1-inactive,
.steps-wizard-dot-2-inactive,
.steps-wizard-dot-3-inactive,
.steps-wizard-dot-4-inactive {
  fill: var(--color-grey);
  stroke-width: 4;
  stroke: var(--color-grey);
}

.steps-wizard-dot-0-completed,
.steps-wizard-dot-1-completed,
.steps-wizard-dot-2-completed,
.steps-wizard-dot-3-completed,
.steps-wizard-dot-4-completed {
  fill: var(--color-pink);
  stroke-width: 4;
  stroke: var(--color-pink);
}

.steps-wizard-line {
  stroke: var(--color-pink);
  stroke-width: 1;
}

.steps-wizard-text {
  font-family: var(--font-din);
  font-size: 14px;
}

.steps-wizard-text-0-active,
.steps-wizard-text-1-active,
.steps-wizard-text-2-active,
.steps-wizard-text-3-active,
.steps-wizard-text-4-active {
  fill: var(--color-white);
}

.steps-wizard-text-0-inactive,
.steps-wizard-text-1-inactive,
.steps-wizard-text-2-inactive,
.steps-wizard-text-3-inactive,
.steps-wizard-text-4-inactive {
  fill: var(--color-white);
}

@media (max-width: 919px) {
  .steps-wizard-container {
    display: none;
  }
}
