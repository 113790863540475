/** @format */

.terms-policy-registration-view {
  margin: 18px;
}

.terms-policy-container {
  font-size: 14px;
  text-align: justify;
}

.terms-policy-content-dual-column {
  width: 77.8%;
  column-count: 2;
  column-gap: 50px;
  margin: auto auto 100px;
}

.terms-policy-container-single-column {
  column-count: 1 !important;
}

.terms-policy-h1 {
  font-family: var(--font-din);
  font-size: 26px;

  text-transform: uppercase;
  text-align: left;
}

.terms-policy-h2 {
  font-family: var(--font-playfair);
  font-size: 20px;
  font-weight: bold;
  margin: 16px 0;
  text-align: left;
}

.terms-policy-h3 {
  font-family: var(--font-roboto);
  font-size: 14px;
  font-weight: bold;
  margin: 14px 0;
  text-align: left;
}

.terms-policy-h4 {
  font-size: 14px;
  font-weight: bold;
  margin: 12px 0 0;
  text-align: left;
}

.terms-policy-container p a,
.terms-policy-email a,
.terms-policy-url a {
  color: var(--color-black);
  font-weight: bold;
  font-size: small;
  text-decoration: underline;
  word-break: break-word;
}

.terms-policy-address-content {
  padding: 0 0 16px;
}

.prev-button-terms-and-policy {
  position: unset;
}

.image-sustainability {
  background-image: var(--image-sustainability);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1024px;
  height: 482px;
  margin: 50px auto;
}

.terms-policy-view #registration-flow .go-previous-button {
  margin: -1px 19px;
}

@media (max-width: 919px) {
  .terms-policy-registration-view {
    margin: 18px 0;
  }

  .terms-policy-h1 {
    margin-left: 2%;
    width: unset;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .terms-policy-registration-view .terms-policy-h1 {
    padding-top: 20px;
  }
}

@media (max-width: 899px) {
  .terms-policy-h1 {
    width: unset;
    margin-bottom: 34px;
  }

  .image-sustainability {
    height: 400px;
  }
}

@media (min-width: 700px) {
  .terms-policy-registration-view .terms-policy-content {
    padding: 50px 0;
  }
}

@media (max-width: 699px) {
  .terms-policy-content-dual-column {
    width: 95%;
    column-count: 1;
  }

  .terms-policy-content {
    padding: unset;
  }

  .terms-policy-h1 {
    width: unset;
    margin-bottom: 34px;
  }

  .terms-policy-registration-view .terms-policy-h1 {
    padding-top: 72px;
  }

  .image-sustainability {
    height: 310px;
  }
}

@media (max-width: 549px) {
  .image-sustainability {
    height: 235px;
  }
}

@media (max-width: 399px) {
  .image-sustainability {
    height: 166px;
  }
}
