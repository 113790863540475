/** @format */

.onboarding-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0px;
  background-color: var(color-grey-light);
  align-items: center;
  margin: 20px auto;
  padding-top: 5px;
  flex: 1 1;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.header-right-menu .header-right-profile-image {
  flex: 1;
}

.circle-white {
  stroke: white;
  fill: white;
}

.circle-white .icon-profile {
  top: -63px;
  left: 24px;
}

.header-right-menu .header-right-profile-details {
  flex: 5;
  line-height: normal;
  margin: auto;
}
.header-right .header-right-menu {
  flex: 2;
  margin-right: 15px;
}
.onboarding-body {
  flex: 1 1;
  width: 100%;

  background-color: white;
  border-radius: 10px;
}
.onboarding-body .onboarding-content {
  padding: 50px;
}

.onboarding-container .logo-the-third-act-white {
  width: 110px;
  height: 64px;
  margin: 32px 60px;
}

.onboarding-container .logout-button-container {
  margin: 160px 25px;
}

.mobile-app-icons-container {
  display: flex;
  flex-direction: row;
  width: 300px;
}

.mobile-app-icons-container a {
  flex: 1;
}

.mobile-app-icons-container .logo-appstore-black,
.mobile-app-icons-container .logo-playstore-black {
  width: 182px;
  height: 64px;
}

@media (max-width: 699px) {
  .mobile-app-icons-container {
    width: 280px;
  }

  .mobile-app-icons-container .logo-appstore-black,
  .mobile-app-icons-container .logo-playstore-black {
    width: 151px;
    height: 53px;
  }
}

@media (max-width: 499px) {
  .header-right-menu .header-right-profile-image {
    display: none;
  }
  .header-right-menu.image-hamburger {
    margin-top: 10px;
  }
}

@media (max-width: 919px) {
  .mobile-app-icons-container {
    width: 250px;
  }
  .mobile-app-icons-container .logo-appstore-black,
  .mobile-app-icons-container .logo-playstore-black {
    width: 126px;
    height: 44px;
  }
}
