/** @format */

@media (max-width: 919px) {
  .mobile-header {
    height: 72px;
    background-color: var(--color-black);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -16px;
    width: calc(100% + 31px);
  }

  .mobile-header-left {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .mobile-header-center {
    flex: 2;
  }

  .mobile-header-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 72px;
  }

  .mobile-header-logo-container {
    width: 110px;
    height: 64px;
    margin-left: 24px;
  }

  .mobile-header-logo-container .logo-the-third-act-white {
    width: 73px;
    height: 42px;
    margin: 10px -6px;
  }

  .mobile-header-right-left-block {
    flex: 3;
    display: flex;
    height: 71px;
    align-items: center;
  }

  .mobile-header-right-left-block .profile-picture {
    flex: 1;
  }

  .mobile-header-right-left-block .profile-picture .icon-profile {
    width: 32px;
    height: 32px;
  }

  .mobile-header-right-left-block .profile-info {
    flex: 2;
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    align-self: center;
    margin-left: 10px;
  }

  .mobile-header-right-left-block .profile-name {
    flex: 1;
    width: 180px;
  }

  .mobile-header-right-left-block .profile-location {
    flex: 1;
    font-size: 12px;
    text-transform: uppercase;
  }

  .mobile-header-right-right-block {
    flex: 1;
    justify-self: flex-end;
    align-self: center;
    text-align: right;
    text-align: -webkit-right;
  }

  .mobile-header-right-right-block .hamburger-menu {
    background-image: var(--icon-hamburger);
    background-position: center;
    background-size: cover;
    height: 24px;
    width: 22px;
    margin: 22px auto;
  }

  .mobile-header-right-right-block .navbar {
    width: 54px;
  }

  .mobile-header-right-right-block
    .mobile-hamburger-menu
    .dropdown-toggle::after {
    border: unset !important;
  }

  .icon-arrow-left {
    background-image: var(--icon-arrow-left);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 18px;
    margin: 27px 0 0;
  }

  .icon-arrow-left:hover,
  .icon-arrow-left:focus {
    cursor: pointer;
  }
  .mobile-header-right-right-block {
    margin: auto 0;
    height: 72px;
  }
  .mobile-logout-button-container {
    display: block;
  }
}

@media screen and (max-width: 919px) {
  .icon-arrow-left {
    margin: 0px;
  }
}

@media (min-width: 624px) {
  .mobile-header-right-left-block .profile-info {
    display: block;
  }
}

@media (max-width: 623px) {
  .mobile-header-right-left-block .profile-info {
    display: none;
  }
}
