/** @format */

.header {
  height: 119px;
  background-color: var(--color-black);
  margin-left: -16px;
  width: calc(100% + 31px);
}

.header-inner {
  height: 119px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  max-width: 1200px;
}

.header-left {
  flex: 1;
  display: flex;
  align-items: center;
}

.header-logo-container {
  width: 110px;
  height: 64px;
  margin-left: 7%;
}

.header-logo-container .logo-the-third-act-white {
  width: 110px;
  height: 64px;
  margin: unset;
}

.header-center {
  flex: 2;
}

.header-right {
  flex: 2;
  display: flex;
  flex-direction: row;
}

.header-right-left-block {
  flex: 3;
  display: flex;
}

.header-right-left-block .profile-picture {
  flex: 1;
}

.header-right-left-block .profile-picture .icon-profile {
  width: 54px;
  height: 54px;
}

.header-right-left-block .profile-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  align-self: center;
  margin-left: 10px;
}

.header-right-left-block .profile-name {
  flex: 1;
  width: 180px;
  letter-spacing: 0.7px;
}

.header-right-left-block .profile-location {
  flex: 1;
  font-size: 12px;
  text-transform: uppercase;
}

.header-right-right-block {
  flex: 1;
  justify-self: flex-end;
  align-self: center;
  text-align: right;
  text-align: -webkit-right;
  position: absolute;
  right: 0;
  left: auto;
  height: 35px;
}

.header-right-right-block .hamburger-menu {
  background-image: var(--icon-hamburger);
  background-position: center;
  background-size: cover;
  height: 26px;
  width: 34px;
  margin: 40px 0;
}

.header-right-right-block .dropdown-toggle::after {
  border: unset !important;
}

.header-right-right-block .hamburger-dropdown {
  margin-top: 30px !important;
  width: 40px !important;
  height: 70px !important;
}

.header-right-right-block .navbar {
  width: 58px;
}

@media screen and (max-width: 1199px) {
  .header {
    font-size: 12px;
  }
}

@media (max-width: 919px) {
  .header-right-right-block {
    margin: auto 40px auto 15px;
  }
}

@media (min-width: 624px) {
  .header-right-left-block .profile-info {
    display: block;
  }
}

@media (max-width: 623px) {
  .header-logo-container {
    margin-left: 25px;
  }
  .header-right-left-block .profile-info {
    display: none;
  }
  .header-right-right-block {
    margin: auto 50px auto 15px;
  }
}
