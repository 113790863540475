/** @format */

.dashboard-view {
  margin: 0 auto;
}

.dashboard-container {
  background-color: var(--color-white);
  min-height: 530px;
  max-width: 1200px;
  margin: 20px auto;
  border-radius: 10px 10px 0px 0px;
}

.dashboard-content-wrapper {
  padding: 25px;
}

.tab-container {
  background-color: var(--color-grey-semi-dark);
  border-radius: 10px 10px 0px 0px;
  margin-left: 0px;
  padding-left: 5px;
  border-bottom: 1px solid var(--color-grey-semi-dark);
  margin: -25px;
}

.tab-content .tab-pane {
  width: 98%;
  margin: auto;
}

.nav-tabs .nav-link {
  width: 170px;
  height: 50px;
  background-color: var(--color-grey-semi-dark);
  color: var(--color-white);
  text-decoration: none;
  background-repeat: no-repeat;
  background-position: 22px;
  padding: 13px 70px;
  border-bottom: 5px solid var(--color-grey-semi-dark);
  background-size: 15%;
}

.nav-tabs .nav-link:first-child {
  border-radius: 10px 0px 0px 0px;
  margin-left: -5px;
}

.nav-tabs .nav-link:hover {
  border: 0;
  background-color: var(--color-grey-dark);
  border-bottom: 5px solid var(--color-pink);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  background-color: var(--color-grey-dark);
  border-bottom: 2px solid var(--color-pink) !important;
  color: var(--color-white);
}

#tab-tab-dashboard {
  background-image: var(--icon-dashboard);
}

#tab-tab-billing {
  background-image: var(--icon-billing);
}

#tab-tab-employees {
  background-image: var(--icon-employees);
}

.dashboard-view .pending-approval-alert-btn-small {
  width: 100px;
  height: 35px;
  padding: 1px;
  border: 3px solid var(--color-pink-dark);
}

.dashboard-view .pending-approval-alert {
  margin: -16px -16px 32px;
  color: var(--color-black);
  background-color: var(--color-pink-light);
  outline: 1px solid var(--color-pink);
  display: flex;
  height: 45px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.table_container {
  max-width: 750px;
  /* max-height: 500px; */
  margin: 0 auto;
  overflow: auto;
}

.table {
  width: 100%;
  border-spacing: 0;
  font-size: 14px;
}

/** sortable table*/

.table th {
  background: #fff;
  padding: 8px 10px;
  border-bottom: 1px solid #1a1a1a;
  font-weight: bold;
  text-align: left;
}

table th:last-child,
table td:last-child {
  width: 25% !important;
}

.table th.up {
  background-image: var(--icon-table-up-arrow);
}
.table th.down {
  background-image: var(--icon-table-down-arrow);
}
.table th.default {
  background-image: var(--icon-table-default-arrow);
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.table td {
  border-top: 1px solid var(--color-grey-light);
  padding: 8px 20px;
}

.table tbody tr:first-child td {
  border-top: none;
}

.table tbody tr td:last-child {
  font-size: 14px;
}

.table tbody tr:nth-child(n) td {
  background: var(--color-grey-light);
}

.table tbody tr:nth-child(2n) td {
  background: var(--color-white);
}

.homepage-tabitem-header-container {
  display: flex;
  margin: 35px auto;
}

.homepage-tabitem-header-left {
  flex: 4;
}

.homepage-tabitem-header-left h1 {
  font-size: 46px;
}

.table tbody tr:nth-child(n):hover td {
  background-color: var(--color-pink-light) !important;
  border-top: 1px solid var(--color-pink) !important;
  border-bottom: 1px solid var(--color-pink-dark) !important;
  cursor: pointer;
}

.table tbody tr:nth-child(n):active td {
  background-color: var(--color-pink-light) !important;
  border-top: 1px solid var(--color-pink) !important;
  border-bottom: 1px solid var(--color-pink-dark) !important;
  opacity: 0.8;
  cursor: pointer;
}

.table-striped tbody tr {
  border-bottom: 2px solid var(--color-pink-light) !important;
}

.table-striped tbody tr:hover {
  border-bottom: 2px solid var(--color-pink-dark) !important;
}

.homepage-tabitem-header-right {
  width: 250px;
  flex: none;
  display: flex;
  flex-direction: row;
  font-family: var(--font-din);
  font-size: 20px;
}

.add-new-button {
  text-decoration: none;
  display: inline-block;
  border-radius: 50%;
  border: 0;
  margin-top: -8px;
}

.add-new-button.btn {
  width: 36px !important;
  height: 36px !important;
  min-width: none !important;
}

.add-new-button:hover {
  background-image: var(--icon-plus-hover);
  cursor: pointer;
}

.add-new-button:active {
  opacity: 90%;
  -moz-box-shadow: inset 0 0 10px var(--color-pink-dark);
  -webkit-box-shadow: inset 0 0 10px var(--color-pink-dark);
  box-shadow: inset 0 0 10px var(--color-pink-dark);
}

.add-new-button:focus,
.add-new-button:focus-visible {
  outline: 2px solid var(--color-pink-dark);
  box-shadow: 0 0 0 0.2rem var(--color-pink-light);
}

.icon-plus {
  background-image: var(--icon-plus);
  background-repeat: no-repeat;
  background-size: auto;
  width: 36px;
  height: 36px;
  margin: -6px auto !important;
}

.stats-container {
  display: flex;
  font-family: var(--font-playfair);
}

.stats-container:hover {
  cursor: pointer;
}

.stats-box {
  height: 140px;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.stats-box h2 {
  font-size: 32px;
  flex: 1;
  letter-spacing: -0.8px;
}

.stats-number {
  font-size: 52px;
  flex: 1;
}

.horizontal-divider.color-grey {
  background-color: var(--color-grey-medium);
  margin-top: 90px;
}

@media (min-width: 600px) {
  .dashboard-view {
    height: 282px;
  }
}

@media (min-width: 920px) and (max-width: 599px) {
  .dashboard-view {
    height: 40px;
    margin: 0 auto;
  }
  .dashboard-container {
    margin-top: 20px;
  }
}

@media (max-width: 919px) {
  .dashboard-view {
    margin: 0 auto;
    height: 0;
  }

  .dashboard-content-wrapper {
    font-size: small;
  }

  .dashboard-content-wrapper h1 {
    font-size: large;
  }

  .dashboard-container {
    margin: 0 -15px;
    min-height: 500px;
    border-radius: unset;
  }
}
