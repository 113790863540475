/** @format */

.logo-the-third-act {
  background-image: var(--logo-the-third-act);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 164px;
  height: 96px;
  margin: 1.1em auto;
}

.logo-the-third-act-white {
  background-image: var(--logo-the-third-act-white);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 170px;
  height: 100px;
  margin: 18px auto 26px auto;
}

.logo-bankid {
  background-image: var(--logo-bankid);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 56px;
  margin: auto;
}

.logo-fullmaktskollen {
  background-image: var(--logo-fullmaktskollen);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 194px;
  height: 33px;
  margin-top: 10px;
}

.logo-appstore-black {
  background-image: var(--logo-appstore-black);
  background-size: 90.2%;
  background-repeat: no-repeat;
  background-position: center;
  width: 226px;
  height: 79px;
}

.logo-playstore-black {
  background-image: var(--logo-playstore-black);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 226px;
  height: 79px;
}

.icon-profile {
  background-image: var(--icon-profile);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  height: 25px;
  margin: 14px;
}

.icon-invoice {
  background-image: var(--icon-invoice);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 33px;
  height: 29px;
}

.icon-payment-error {
  background-image: var(--icon-payment-error);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}

.icon-profile-square,
.icon-mobile-phone-square,
.icon-email-square,
.icon-location-square,
.icon-salary-square,
.icon-family-square,
.icon-subscription-square {
  margin: 0 10px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-profile-square {
  background-image: var(--icon-profile-square-background);
}

.icon-mobile-phone-square {
  background-image: var(--icon-mobile-phone-square-background);
}

.icon-email-square {
  background-image: var(--icon-email-square-background);
}

.icon-location-square {
  background-image: var(--icon-location-square-background);
}

.icon-salary-square {
  background-image: var(--icon-salary-square-background);
}

.icon-family-square {
  background-image: var(--icon-family-square-background);
}
.icon-subscription-square {
  background-image: var(--icon-subscription-square-background);
}

.loading-indicator-container {
  flex: 1;
  width: 100px;
  margin: 150px auto;
}

.button-container .btn {
  margin-top: 20px;
}

.button-loader {
  width: 62px;
  margin: auto;
}

.color-pink {
  color: var(--color-pink);
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.pink-dot {
  color: var(--color-pink);
  font-size: 26px;
}

a {
  color: var(--color-black);
  text-decoration: underline;
  font-weight: bold;
}

a:hover {
  color: var(--color-grey-dark);
}

a:focus,
a:active {
  color: var(--color-grey-medium);
}

.small-screen-alert button {
  width: 12px;
  height: 12px;
  right: 5vw !important;
  top: 5vh !important;
}

.small-screen-alert button:focus {
  outline: unset !important;
}

/* style for users who are already registered and re-subscribing*/
.profile-resubscription-flow-container {
  margin: 0 auto;
}

/* style for users who are already registered and re-subscribing*/
.profile-resubscription-flow-container .content {
  margin: 0 auto !important;
  display: block !important;
  width: 90% !important;
}

.alert.alert-warning {
  margin: 15px -15px 15px;
  border: 1px solid var(--color-pink-light);
}

@media (min-width: 920px) and (max-width: 989px) {
  .session-expiry-alert {
    padding-right: 120px;
  }
}

@media (max-width: 919px) {
  .session-expiry-alert {
    padding-right: initial;
  }
  .alert.alert-warning {
    margin: 15px -15px 15px;
    border-radius: 0;
  }
  .profile-resubscription-flow-container .content {
    width: 100% !important;
  }
}
