/** @format */

.logout-button-container {
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 10px;
  top: 10px;
  text-align: center;
  z-index: 100000;
}

.logout-button-text {
  width: 100px;
  flex: 2;
  align-self: center;
  font-weight: bold;
  text-decoration: underline;
}
.logout-button-container svg {
  flex: 1;
}
.logout-button-circle {
  stroke: var(--color-grey-dark);
  stroke-width: 2;
  fill: var(--color-grey-light);
}
.logout-button-line {
  stroke: var(--color-grey-semi-dark);
  stroke-width: 2;
}

.logout-button-container:hover {
  cursor: pointer;
}

.logout-button-container:hover .logout-button-circle {
  stroke: var(--color-grey-dark);
  stroke-width: 3;
}

.logout-button-container:hover .logout-button-line {
  stroke: var(--color-pink);
  stroke-width: 3;
}
.logout-button-container:hover .logout-button-text {
  color: var(--color-pink);
  text-decoration: underline;
}

@media (max-width: 919px) {
  .logout-button-container {
    display: none;
  }
}
