/** @format */

.go-previous-button {
  position: fixed;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  display: inline-block;
  background-color: var(--color-pink);
  background-image: var(--icon-go-previous);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1000;
}

.go-previous-button-dashboard {
  position: unset;
  background-color: var(--color-white);
  background-image: var(--icon-previous-pink);
  width: 64px;
  height: 64px;
  margin: 25px;
  min-width: 64px;
}

.go-previous-button.btn,
.go-previous-button-dashboard.btn {
  min-width: unset;
}

.go-previous-button:hover {
  background-color: var(--color-pink-light) !important;
  border-color: var(--color-white) !important;
  border-radius: 50% 50%;
}

.go-previous-button:active {
  background-color: var(--color-pink-light) !important;
  border: 2px solid var(--color-pink) !important;
}

.go-previous-button-hide {
  display: none;
}

@media (max-width: 919px) {
  .go-previous-button {
    display: none;
  }
}
