/** @format */

.image-background-page-not-found {
  background-image: var(--image-background-page-not-found);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 95.5vh;
}

.horizontal-divider {
  background-color: var(--color-pink);
}

.page-not-found-container {
  text-align: center;
}

.page-not-found-container h1 {
  font-size: 70px;
}
.page-not-found-container h2 {
  font-size: 40px;
}

.page-not-found-description p {
  font-size: 30px;
}
